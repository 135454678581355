const authToken = {
  getAccessToken: () => {
    let token = localStorage.getItem("token");
    return token;
    //  {
    // accessToken: sessionStorage.getItem("token"),
    // refreshToken: sessionStorage.getItem("rtoken"),
    // };
  },
  isAuthenticated: () => {
    let accessToken = localStorage.getItem("token");
    return accessToken;
  },
  setAccessToken: (access_token, refresh_token) => {
    localStorage.setItem("token", access_token);
    localStorage.setItem("rtoken", refresh_token);
  },
  removeAccessToken: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("rtoken");
  },
};

export default authToken;

//   getAccessToken() {
//     return this.getAccessToken().accessToken;
//   },

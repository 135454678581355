<template>
  <v-dialog
    v-model="raiseTicket"
    width="644px !important"
    @click:outside="closeDialog"
    class="ma-0"
  >
    <v-card class="elevation-6 dialog_card">
      <div class="dialog_header">
        <h3 class="custom-p">Raise Support Request</h3>
        <v-spacer></v-spacer>
        <v-btn icon class="color--white btn_bg" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider color="white" style="width: 100%"></v-divider>
      <v-card-text class="card_form pa-0">
        <v-form
          class="custom-form"
          ref="form_reset"
          @submit.prevent="submitForm"
        >
          <v-row dense>
            <!-- Dropdown Field -->
            <v-col cols="12">
              <v-label class="custom-label">Issue Category*</v-label>
              <v-autocomplete
                v-model="category"
                :items="categoryList"
                placeholder="Select Category"
                item-text="title"
                :rules="[this.$rules.required]"
                :readonly="itemStatus"
                required
                outlined
                style="border-radius: 8px"
                color="green lighten-1"
                dense
                class="mt-2 select"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-label class="custom-label">Issue Description*</v-label>
              <v-text-field
                v-model="description"
                placeholder="Describe your issue..."
                :rules="[this.$rules.required]"
                :readonly="itemStatus"
                outlined
                dense
                class="mt-2"
              ></v-text-field>
            </v-col>
            <!-- file upload 1 -->
            <v-col cols="12">
              <!-- header  -->
              <div class="file_field mt-2">
                <p
                  v-if="
                    !uploadableImageFile &&
                    viewUploadedMedia == null &&
                    itemStatus == false
                  "
                  class="textcenter color--grey"
                >
                  Choose a file to upload
                </p>

                <p
                  v-else-if="viewUploadedMedia !== null && itemStatus == true"
                  class="textcenter color--grey"
                >
                  View Uploaded Media
                </p>
                <p v-else class="textcenter color--grey">No Media Uploaded</p>
                <label for="fileInput" class="custom-file-input-label">
                  <div
                    class="upload_btn"
                    v-if="viewUploadedMedia == null && itemStatus == false"
                  >
                    <v-btn
                      outlined
                      dense
                      class="upload-file"
                      @click="handleFileUpload"
                    >
                      <v-icon left>{{ uploadIcon }}</v-icon>
                      {{ uploadText }}
                    </v-btn>
                    <v-btn
                      outlined
                      dense
                      class="upload-file"
                      v-if="fileUploaded"
                      @click="removeFile"
                    >
                      <v-icon color="error" left>mdi-delete</v-icon>
                      Remove
                    </v-btn>
                  </div>
                  <div
                    class="upload_btn"
                    v-if="viewUploadedMedia !== null && itemStatus == true"
                  >
                    <v-btn
                      outlined
                      small
                      dense
                      class="preview-eye pa-0"
                      @click="showUploadedMedia"
                    >
                      <v-icon small class="mr-1">mdi-eye</v-icon> View Media
                    </v-btn>
                  </div>
                  <span v-if="uploadableImageFile" class="file_name mt-2">
                    {{ uploadableImageFile.name }}
                    <!-- View Button -->
                    <v-btn
                      outlined
                      small
                      dense
                      class="preview-eye pa-0"
                      v-if="fileUploaded"
                      @click="showPreview"
                    >
                      <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                  </span>
                </label>
              </div>
              <input
                type="file"
                ref="input_field"
                style="visibility: hidden"
                @change="upload_file"
              />
            </v-col>
          </v-row>
          <!-- <v-divider color="white" style="width: 100%"></v-divider> -->

          <v-btn
            @click="addRaiseTicket"
            class="model_btn"
            color="white"
            :loading="loading"
            :disabled="loading"
            outlined
            dark
            dense
            >Raise Ticket</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "@/main";

export default {
  name: "ticketDialog",

  data() {
    return {
      loading: false,
      fileError: false,
      description: "",
      category: "",
      itemStatus: false,
      ticketDetails: {},
      uploadableImageFile: "",
      imagePreview: null,
      fileUploaded: false,
      viewUploadedMedia: null,
      categoryList: [
        { title: "Report a bug" },
        { title: "Request a new feature" },
        { title: "Not satisfied with the product?" },
        { title: "Report other issues" },
      ],
      uploadIcon: "mdi-upload",
      uploadText: "Upload Document",
    };
  },
  computed: {
    raiseTicket: {
      get() {
        return this.$store.state.dialog.raiseTicket.show;
      },
      set(obj) {
        this.$store.commit("dialog/setRaiseTicket", obj.show);
      },
    },
    item() {
      return this.$store.state.dialog.raiseTicket.item;
    },
  },
  watch: {
    item(newValue) {
      if (newValue === null) {
        this.resetForm();
        this.itemStatus = false;
      } else {
        this.getTicketDetail();
        this.itemStatus = true;
      }
    },
    ticketDetails() {
      if (this.ticketDetails != null) this.prefillFormFields();
    },
  },
  methods: {
    handleFileUpload() {
      this.$refs.input_field.click();
    },
    upload_file() {
      const includeTypes = ["image/png", "image/jpeg"]; // Specify allowed MIME types
      const imgFile = this.$refs.input_field.files[0];
      if (!includeTypes.includes(imgFile.type)) {
        this.fileError = true;
        alert("Invalid file type. PDF, MP4, and MP3 files are not allowed.");
      } else {
        this.fileUploaded = true;
        this.fileError = false;
        this.uploadableImageFile = imgFile;
      }
    },

    addRaiseTicket() {
      this.loading = true;

      const successHandler = (res) => {
        this.loading = false;

        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "green",
        });
        this.closeDialog();
        eventBus.$emit("call-ticket-list");
      };

      const failureHandler = (res) => {
        this.loading = false;
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "red",
        });
      };
      const newData = new FormData();
      newData.append("category", this.category);
      newData.append("description", this.description);
      newData.append("media", this.uploadableImageFile);
      const params = newData;

      return this.$axios("post", this.$apiUrl.raiseTicket, {
        data: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    closeDialog() {
      this.$store.commit("dialog/setRaiseTicket", { show: false });
      this.resetForm();
    },
    removeFile() {
      (this.uploadableImageFile = null), (this.fileUploaded = false);
      this.$refs.input_field.value = "";
      this.imagePreview = null;
    },
    showPreview() {
      if (this.uploadableImageFile) {
        this.imagePreview = URL.createObjectURL(this.uploadableImageFile);
        window.open(this.imagePreview, "_blank");
      }
    },
    showUploadedMedia() {
      if (this.viewUploadedMedia) {
        this.imagePreview = this.viewUploadedMedia;
        window.open(this.imagePreview, "_blank");
      }
    },
    getTicketDetail() {
      const successHandler = (res) => {
        this.ticketDetails = res.data.result;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      return this.$axios(
        "get",
        this.$apiUrl.getTicketDetail + this.item.id + "/",
        {
          onSuccess: successHandler,
          onFailure: failureHandler,
          isTokenRequired: true,
        }
      );
    },
    prefillFormFields() {
      this.description = this.ticketDetails.description;
      this.category = this.ticketDetails.category;
      if (this.ticketDetails.media) {
        this.viewUploadedMedia = this.ticketDetails.media;
      }
    },
    resetForm() {
      this.$refs.form_reset.reset();
      this.removeFile();
      this.viewUploadedMedia = null;
    },
  },
};
</script>

<style scoped>
.card_form {
  padding: 30px 0px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(127, 132, 138, 1);
}
.v-text-field--placeholder >>> input::placeholder {
  color: rgba(127, 132, 138, 1);
}
.v-input >>> input {
  color: rgba(255, 255, 255, 0.87);
}
/* .v-icon {
      color: rgba(103, 184, 39, 1) !important;
    } */
.btn_bg {
  background-color: #fff;
  height: 24px;
  width: 24px;
}
.model_btn {
  margin: 0px 26% !important;
  margin-top: 28px !important;
  width: 210px;
  background: rgba(176, 203, 66, 1);
  border: none;
  border-radius: 10px;
}
.theme--light.v-select >>> .v-select__selections {
  color: rgb(255 255 255 / 87%);
}

.dialog_header {
  display: flex;
  padding: 20px;
}
.dialog_card {
  border: 0.4px;
  height: auto;
  width: 650px;
  background: rgba(32, 32, 34, 1) !important;
  color: #fff !important;
  border-radius: 16px !important;
}
.select >>> .mdi-menu-down::before {
  color: antiquewhite !important;
}
.file_field {
  padding: 12px 16px;
  border: 2px dotted rgba(127, 132, 138, 1);
}
.upload_btn {
  display: flex;
  justify-content: space-evenly;
}
.upload-file {
  border-color: rgba(176, 203, 66, 1);
  color: rgba(176, 203, 66, 1);
  border-radius: 20px;
  text-transform: capitalize;
}
.preview-eye {
  color: rgba(176, 203, 66, 1);
  border: none;
}
.file_name {
  color: rgba(176, 203, 66, 1);
  display: block;
  text-align: center;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Base from "@/components/layout/Base.vue";
import SignUp from "../views/authPages/SignUp.vue";
import authToken from "@/app-js/authToken";

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin",
    name: "admin",
    component: Base,
    children: [
      // Dashboard pages
      {
        path: "dashboard",
        name: "dashboard",
        meta: { requiresAuth: true, title: "Dashboard" },
        component: () => import("../views/Dashboard.vue"),
      },

      // infrastructure pages

      {
        path: "infrastructure",
        name: "infrastructure",
        meta: { requiresAuth: true, title: "Infrastructure" },
        component: () =>
          import("../views/infrastructurePage/Infrastructure.vue"),
      },

      {
        path: "modeldataset",
        name: "modeldataset",
        meta: { requiresAuth: true, title: "Models & Datasets" },
        component: () => import("../views/modelsAndDatasets/modelDataset.vue"),
      },
      // kompress
      {
        path: "kompress",
        name: "kompress",
        meta: { requiresAuth: true, title: "Kompress" },
        component: () => import("../views/kompress/kompressPage.vue"),
      },
      {
        path: "kompressHistory",
        name: "kompressHistory",
        meta: { requiresAuth: true, title: "Kompress" },
        component: () => import("../views/kompress/kompressHistoryPage.vue"),
      },
      {
        path: "jobTask",
        name: "jobTask",
        meta: { requiresAuth: true, title: "Jobs" },
        component: () => import("../views/kompress/jobTaskPage.vue"),
      },
      {
        path: "adapt",
        name: "adapt",
        meta: { requiresAuth: true, title: "adapt" },
        component: () => import("../views/adapt/adaptForm.vue"),
      },
      {
        path: "tracker",
        name: "tracker",
        meta: { requiresAuth: true, title: "tracker" },
        component: () => import("../views/trackerPg/trackerForm.vue"),
      },
      {
        path: "analyse",
        name: "analyse",
        meta: { requiresAuth: true, title: "Results" },
        component: () => import("../views/trackerPg/analysePage.vue"),
      },
      {
        path: "userProfile",
        name: "userProfile",
        meta: { requiresAuth: true, title: "User Profile" },
        component: () => import("../views/userProfilePage.vue"),
      },
      {
        path: "settings",
        name: "settings",
        meta: { requiresAuth: true, title: "Settings" },
        component: () => import("../views/settingsPage.vue"),
      },
      {
        path: "helpAndSupport",
        name: "helpAndSupport",
        meta: { requiresAuth: true, title: "Help & Support" },
        component: () => import("../views/helpSupportPage.vue"),
      },
    ],
  },
  {
    path: "",
    name: "signup",
    component: SignUp,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: () => import("../views/authPages/LogIn.vue"),
  },
  {
    path: "/forgetpassword",
    name: "forgetpassword",
    meta: { requiresAuth: false },
    component: () => import("../views/authPages/forgetPassword.vue"),
  },
  {
    path: "/passwordverify",
    name: "passwordverify",
    meta: { requiresAuth: false },
    component: () => import("../views/authPages/passwordVerify.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !authToken.isAuthenticated()) {
    // If route requires authentication and user is not authenticated, redirect to login
    next({ name: "login" });
  } else if (to.name === "signup" && authToken.isAuthenticated()) {
    // If the user is already authenticated and tries to go to the login page, redirect to the dashboard
    next({ name: "dashboard" });
  } else {
    // Allow navigation
    next();
  }
});

export default router;

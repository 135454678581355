<template>
  <v-main>
    <v-card class="elevation-6 card_container" style="width: 602px !important">
      <div class="mt-0">
        <h1 class="custom-h1 md-5 text-center">Verify Your Email Address</h1>
        <p class="font-regular color--grey textcenter">
          Nyun.AI has sent you a OTP on the given Email Address
        </p>
      </div>
      <v-card-text class="pa-0">
        <div
          class="ma-auto position-relative custom-otp-input mt-10"
          style="max-width: 436px"
        >
          <p class="font-regular color--white">Enter 6 Digit OTP</p>
          <v-otp-input
            v-model="otp"
            :disabled="loading"
            type="number"
            class="otp"
          ></v-otp-input>
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
          <div class="textcenter pt-4">
            <p class="text-small font-bold color--white mb-0">
              {{ timer }} sec
            </p>
            <v-btn
              class="resendBtn"
              outlined
              dark
              dense
              :disabled="timer > 0"
              @click="resendOtp()"
              >Resend OTP</v-btn
            >
          </div>
        </div>
        <v-card-actions>
          <v-btn
            class="custom-btn"
            type="submit"
            color="white"
            outlined
            dark
            dense
            @click="submitOtp(otp)"
            >Verify</v-btn
          >
        </v-card-actions>
        <!-- {{ this.signupDetails }} -->
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import authToken from "@/app-js/authToken";
export default {
  name: "SignupVerify",
  data: () => ({
    loading: false,
    otp: "",
    text: "",
    timer: 60,
    timerInterval: 0,
  }),
  computed: {
    signupDetails() {
      return this.$store.state.authStore.signupDetails;
    },
  },

  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          // Timer has expired, show error and clear the interval
          clearInterval(this.timerInterval);
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: "OTP verification timed out. Please request a new OTP.",
            type: "red",
          });
        }
      }, 1000); // Decrease the timer every second (1000 milliseconds)
    },
    submitOtp(rsp) {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.loading = false;
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "green",
        });
        clearInterval(this.timerInterval);
        authToken.setAccessToken(
          res.data.result.access,
          res.data.result.refresh
        );
        this.$router.push({ name: "dashboard" });
      };

      const failureHandler = (res) => {
        this.loading = false;

        // Log the entire response for debugging purposes
        console.error("Error Response:", res);

        if (res.status >= 400 && res.status < 500) {
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: "Incorrect OTP. Please try again.",
            type: "red",
          });
        } else if (res.status >= 500 && res.status < 600) {
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: "Server error. Please try again later.",
            type: "red",
          });
        } else {
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: res.statusText,
            type: "red",
          });
        }
      };

      const params = {
        email: this.signupDetails.email,
        country: this.signupDetails.country,
        name: this.signupDetails.name,
        organization_name: this.signupDetails.organization_name,
        password: this.signupDetails.password,
        otp: rsp,
      };
      return this.$axios("post", this.$apiUrl.signUpValidateOtp, {
        data: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },

    resendOtp() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.timer = 60;
        this.startTimer();
        this.loading = false;

        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "green",
        });
      };

      const failureHandler = (res) => {
        this.loading = false;
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.statusText,
          type: "red",
        });
      };
      const params = {
        email: this.signupDetails.email,
        country: this.signupDetails.country,
        country_code: this.signupDetails.country_code,
        mobile: this.signupDetails.mobile,
        name: this.signupDetails.name,
        organization_name: this.signupDetails.organization_name,
        password: this.signupDetails.password,
      };
      return this.$axios("post", this.$apiUrl.signUpApi, {
        data: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },
  },
};
</script>

<style scoped>
.agreement-label {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: 436px;
  color: #6e7b89;
  margin: 0px auto;
}

.link {
  color: #05051a;
  text-decoration: none;
}

.forget-password {
  color: #0d1879;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}
.resendBtn {
  border: none;
  color: #b0cb42;
  text-decoration: underline;
  margin-bottom: 20px !important;
}

.v-otp-input >>> .v-input .v-input__control .v-input__slot {
  background: #202022;
  border: 1px solid #b0cb42;
  color: #b0cb42;
}
.v-otp-input >>> .v-input {
  color: #b0cb42;
}

.otp >>> .theme--light.v-input input,
.theme--light.v-input textarea {
  color: white !important;
}
.card_container {
  margin-top: 102px;
}
</style>

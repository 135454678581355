<template>
  <v-dialog v-model="infraSetup" width="728px !important" class="ma-0">
    <v-card class="elevation-6 card_container" style="width: 728px">
      <v-card-text class="pa-0">
        <div class="d-flex justify-center align-center">
          <span class="ml-2 lineOne">Setup Infrastructure</span>
        </div>
        <div class="mt-3">
          <p class="text-center lineTwo">
            You must first setup your infrastructure before using the Kompress
            feature.
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center align-center mt-8">
        <v-btn @click="closeDialog" class="close_btn">Cancel</v-btn>
        <v-btn @click="pushToInfra" class="proceed_btn">Proceed To Setup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "infraSetupDialog",
  computed: {
    infraSetup: {
      get() {
        return this.$store.state.dialog.infraSetup;
      },
      set(value) {
        this.$store.commit("dialog/setInfraSetup", value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.infraSetup = false;
    },
    pushToInfra() {
      const currentRoute = this.$route.name;

      if (currentRoute !== "infrastructure") {
        this.$router.push({ name: "infrastructure" }).catch(() => {});
        this.infraSetup = false;
      } else {
        this.infraSetup = false;
      }
    },
  },
};
</script>

<style scoped>
.lineOne {
  font-size: 32px;
  color: white;
  font-weight: 600;
  line-height: normal;
}
.lineTwo {
  color: #a7a7a7;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
.proceed_btn {
  padding: 10px 24px !important;
  background-color: rgba(103, 184, 39, 0.64) !important;
  color: white;
  border-radius: 10px;
}
.close_btn {
  background-color: rgba(32, 32, 34, 1) !important;
  color: red;
  border-radius: 10px;
  border: 1px solid var(--m-3-sys-light-outline, #79747e);
}
</style>

const API_URL = {
  // Auth API
  signUpApi: "/user/signup/",
  logInApi: "/user/login/",
  signUpValidateOtp: `user/validate_otp/`,

  // forget password
  forgetPassword: `/user/send_otp/`,
  otpToChangePassword: `/user/verify_otp_and_change_password/`,

  // refresh Token
  refreshTokenApi: `/api/token/refresh/`,

  // infrastructure apis
  //  vpc Form apis
  vpcCreateInfra: `/infra/create_infra/`,

  // infraProviderList: `/master/list_master_infra_provider/`,  not in used

  //nyunCLoud Form apis
  nuynMachineSpecs: `/infra/upload_machine_specification/`,

  gpuTypeList: `/master/list_master_gpu_type/`,
  gpuNumberList: `/master/list_master_gpu_number/`,

  // route check api
  checkModelDatasetUploaded: `/ai_model/is_model_dataset_uploaded/`,
  checkIsInfraSetup: `/infra/is_infra_detail/`,
  checkIsInfraConnected: `/infra/is_infra_and_is_connected/`,

  // infra details API

  listInfraDetails: `/infra/list_infra/`,
  listMachineSpecs: `/infra/list_machine_specification/`,
  connectInfra: `/infra/check_ssh_connection/`,
  deleteInfra: `infra/delete_infra/`,

  // import wizard dialog apis (model and dataset)
  importModel: `/ai_model/upload_ai_model/`,
  listFramework: `/master/list_framework/`,

  importDataset: `/dataset/import_dataset/`,
  listDatasetType: `/master/list_dataset_type/`,

  // models and Datasets page  API

  // listUserModel: `/user/list_user_ai_model/`,
  listUserDataset: `/dataset/list_dataset/`,
  listCountAiModel: `/ai_model/list_and_count_ai_model/`,
  listCountBaseModel: `/ai_model/list_and_count_base_model/`,
  listCountOptimizedModel: `/ai_model/list_and_count_optimized_model/`,
  listCountInProgressdModel: `/ai_model/list_and_count_in_progress_model/`,

  deleteUserModel: `/ai_model/delete_user_ai_model/`,
  deleteUserDataset: `/dataset/delete_dataset/`,
  // exportModel: `/ai_model/get_exported_ai_model/`,
  exportModel: `/infra/user_server_data_upload/`,

  //model upload and dataset upload api

  userServerUpload: `/infra/user_server_data_upload/`,

  // Kompress Apis
  createKompressJob: `/service/create_kompress_job/`,
  kompressFormModelList: `/ai_model/list_user_ai_model/`, //dropdown api
  kompressFormDatasetList: `/dataset/list_user_dataset/`, //dropdown api
  listTask: `/master/list_kompress_task/`, //dropdown api
  kompressJobHistory: `/service/list_kompress_job_history/`,
  deleteKompressJob: `/service/delete_job/`,

  // Job list UI api
  listAllKompressJob: `/service/list_all_job/`,
  downloadJob: `/service/download_job_log/`,
  stopJob: `/service/stop_job/`,

  // listQueuedKompressJob: `/service/list_queued_kompress_job/`,
  // listInProgressKompressJob: `/service/list_in_progress_kompress_job/`,
  // listCompletedKompressJob: `/service/list_completed_kompress_job/`,
  // listFailedKompressJob: `/service/list_failed_kompress_job/`,

  // profile Apis
  userProfileDetails: `/user/get_profile/`,
  editUserProfile: `/user/edit_profile/`,

  // Adapt apis
  createAdapt: `/service/create_adapt_job/`,
  adaptJobHistory: `/service/list_adapt_job_history/`,
  adaptTaskList: `/master/list_adapt_task/`,

  // Filter job method API Jobs
  listKompressMethod: `/master/list_kompress_method/`, // kompress dropdown
  listAdaptMethod: `/master/list_adapt_method/`,

  // Settings
  toggleNotification: `/user/get_notified/`,

  // track analyse
  //--------------dropdown
  listAnalyseTask: `/master/list_analysis_task/`,
  listInputDtype: `/master/list_input_dtype/`,
  listQuantization: `/master/list_quantization/`,
  listCompilers: `/master/list_compilers/`,
  listDevices: `/master/list_devices/`,

  // create analyse
  createAnalyseJob: `/service/create_analysis_job/`,

  updateModelFiles: "/ai_model/update_model_file/",
  updateDataSetFiles: "dataset/update_dataset_file/",

  getExportedAiModel: "ai_model/get_exported_ai_model",
  downloadJobLog: "/service/download_job_log/",

  //help & support
  getTicketList: "/helpdesk/list_tickets/",
  deleteTicket: "/helpdesk/delete_ticket/",
  raiseTicket: "/helpdesk/create_ticket/",
  getTicketDetail: "/helpdesk/get_ticket_detail/",
  reopenTicket: "/helpdesk/reopen_ticket/",
};

export default API_URL;

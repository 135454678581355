<template>
  <div>
    <!-- country field  -->
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-label class="custom-label">Country*</v-label>

        <v-text-field
          v-model="country"
          outlined
          placeholder="Select your country"
          :rules="[isCountryFieldFocused ? required : null]"
          color="deep-purple lighten-1"
          style="border-radius: 8px"
          dense
          class="mt-2"
          :readonly="true"
          v-on="on"
          @focus="isCountryFieldFocused = true"
          @blur="isCountryFieldFocused = false"
        >
          <template v-slot:prepend-inner>
            <vue-country-dropdown
              @onSelect="onSelectCountry"
              @click="isCountryFieldFocused = true"
              :preferredCountries="['US', 'IN']"
              :immediateCallSelectEvent="true"
              :enabledCountryCode="false"
              :showNameInput="True"
              :showNotSelectedOption="true"
              :notSelectedOptionText="'None'"
              class="country-field"
            ></vue-country-dropdown>
          </template>
        </v-text-field>
      </template>
    </v-menu>
  </div>
</template>

<script>
import VueCountryDropdown from "vue-country-dropdown";

export default {
  name: "MenuList",
  components: {
    VueCountryDropdown,
  },
  data() {
    return {
      country: "",
      selectedCountry: "",
      isCountryFieldFocused: false,
      gettingData: {},
    };
  },
  computed: {
    getUserDetails() {
      return this.$store.state.authStore.userDetails;
    },
  },
  // watch: {
  //   onSelectCountry(selectedCountry) {
  //     this.country = selectedCountry.name;
  //   },
  // },
  mounted() {
    this.profileDetails();
  },
  methods: {
    required: (value) => !!value || "This is Required.",

    // emitting country , dialcode, phone to signup page  as selected in text feild
    onSelectCountry(selectedCountry) {
      if (this.isCountryFieldFocused) {
        this.country = selectedCountry.name;
        this.$emit("selectedCountry", selectedCountry);
      }
    },
    async profileDetails() {
      await this.$store.dispatch("authStore/getUserDetails");
      this.gettingData = this.getUserDetails;
      console.log("gettingData", this.gettingData);
      // updating data in the fields initially

      this.country = this.gettingData.country;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(127, 132, 138, 1);
}

.v-text-field--placeholder >>> input::placeholder {
  color: rgba(127, 132, 138, 1);
}

.v-input >>> input {
  color: rgba(255, 255, 255, 0.87);
}

.country-field {
  border: none;
}
.vue-country-select >>> .dropdown {
  background: none !important;
}
.vue-country-select >>> .current {
  padding-bottom: 7px;
}
.vue-country-select >>> .country-code {
  color: #fff;
  margin-right: 3px;
}
.vue-country-select >>> .dropdown-arrow {
  color: #ffffff;
}
::v-deep .v-messages__message {
  color: rgb(255, 0, 0); /* Change the color to your desired color */
}
</style>

<template>
  <v-main>
    <v-app>
      <v-row justify="center" align="center">
        <v-col cols="6">
          <div class="logo-container" justify="center" align="center">
            <img :src="logoNyun" width="414px" alt="Logo" class="logo-img" />
            <h1 class="logo-title">
              Hi! Welcome to <span class="logo-text">nyun.AI</span>
            </h1>
          </div>
        </v-col>
        <v-col cols="6">
          <Signup />
        </v-col>
      </v-row>
    </v-app>
  </v-main>
</template>

<script>
import Signup from "@/components/auth/Signup.vue";
import logoNyun from "@/assets/images/nyunZero.png";

export default {
  name: "SignUp",
  components: {
    Signup,
  },
  data() {
    return {
      logoNyun: logoNyun,
    };
  },
  methods: {},
};
</script>

<style scoped>
.logo-title {
  font-size: 40px;
  font-weight: 700;
  width: 474px;
  margin-top: 40px;
}
.logo-text {
  font-size: 48px;
  color: rgba(176, 203, 66, 1);
}
</style>

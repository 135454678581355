export default {
  namespaced: true,

  state: {
    importModelFlag: false,
    infraSetup: false,
    deleteInfra: false,
    logoutUser: false,
    infraConnected: false,
    editProfileFlag: false,
    exportModelFlag: false,
    optimizeDialogFlag: false,
    comingSoonFlag: false,
    raiseTicket: {
      show: false,
      item: null,
    },
    show: false,
    vmProgressDialog: false,
    selectedTab: "models",
    message: "",
    type: "primary",
    modelUploadRadio: "general_details",
    attachFileRadio: "scp_command",
    dataUploadRadio: "general_details",
    attachedFileDataRadio: "scp_command",
  },
  mutations: {
    setImportModelFlag(state, val) {
      state.importModelFlag = val;
    },
    // infra conditions check
    setInfraSetup(state, val) {
      state.infraSetup = val;
    },
    setDeleteInfra(state, val) {
      state.deleteInfra = val;
    },
    setInfraConnected(state, val) {
      state.infraConnected = val;
    },
    setEditProfileFlag(state, val) {
      state.editProfileFlag = val;
    },
    setExportModelFlag(state, val) {
      state.exportModelFlag = val;
    },
    setOptimizeDialogFlag(state, val) {
      state.optimizeDialogFlag = val;
    },
    setComingSoonFlag(state, val) {
      state.comingSoonFlag = val;
    },
    setSelectedTab(state, selectedTab) {
      state.selectedTab = selectedTab;
    },
    setLogout(state, val) {
      state.logoutUser = val;
    },
    setRaiseTicket(state, obj) {
      state.raiseTicket.show = obj.show !== undefined ? obj.show : true;
      state.raiseTicket.item = obj?.item ? obj.item : null;
    },

    // snackbar global message
    update_snackbar(state, obj) {
      if (obj.show === false) {
        state.show = false;
      } else {
        state.message = obj.message;
        state.type = obj.type;
        state.show = obj.show;
      }
    },
    setModelUploadRadio(state, value) {
      state.modelUploadRadio = value;
    },
    setDataUploadRadio(state, value) {
      state.dataUploadRadio = value;
    },
    setAttachFileRadio(state, value) {
      state.attachFileRadio = value;
    },
    setAttachedFileDataRadio(state, value) {
      state.attachedFileDataRadio = value;
    },
    setVmProgress(state, value) {
      state.vmProgressDialog = value;
    },
  },
  actions: {
    updateSnackbar({ commit }, snackbarData) {
      commit("update_snackbar", snackbarData);
    },
  },
};

<template>
  <v-dialog v-model="editProfileFlag" width="644px !important" class="ma-0">
    <v-card class="elevation-6 dialog_card">
      <div class="dialog_header">
        <h3 class="custom-p">Edit Profile</h3>
        <v-spacer></v-spacer>
        <v-btn icon class="color--white btn_bg" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider color="white" style="width: 100%"></v-divider>
      <v-card-text class="card_form pa-0">
        <v-form class="custom-form" @submit.prevent="submitForm">
          <v-row dense>
            <!-- Dropdown Field -->
            <v-col cols="12">
              <v-label class="custom-label">New Name</v-label>
              <v-text-field
                v-model="new_name"
                placeholder="Enter your new name"
                outlined
                dense
                class="mt-2"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-label class="custom-label">New Organization Name</v-label>
              <v-text-field
                v-model="organization_name"
                placeholder="Enter your new name"
                outlined
                dense
                class="mt-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-divider color="white" style="width: 100%"></v-divider> -->
          <!-- <MenuList @selectedCountry="handleCountryEvent" /> -->
          <v-btn
            class="model_btn"
            color="white"
            :loading="loading"
            outlined
            dark
            dense
            @click="saveChanges"
            >Save Changes</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import MenuList from "../MenuList.vue";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

export default {
  name: "editProfile",
  // components: {
  //   MenuList,
  // },
  data() {
    return {
      loading: false,
      new_name: "",
      organization_name: "",
      country_name: "",
      gettingData: {},

      unicodeFlag: "",
    };
  },
  computed: {
    editProfileFlag: {
      get() {
        return this.$store.state.dialog.editProfileFlag;
      },
      set(value) {
        this.$store.commit("dialog/setEditProfileFlag", value);
      },
    },
    signupDetails() {
      return this.$store.state.authStore.signupDetails;
    },
    getUserDetails() {
      return this.$store.state.authStore.userDetails;
    },
  },
  created() {
    this.profileDetails();
  },
  methods: {
    closeDialog() {
      this.editProfileFlag = false;
    },
    async profileDetails() {
      await this.$store.dispatch("authStore/getUserDetails");
      this.gettingData = this.getUserDetails;
      // updating data in the fields initially
      this.new_name = this.gettingData.name;
      this.organization_name = this.gettingData.organization_name;
      this.country_name = this.gettingData.country;

      let code = this.gettingData.country_code || "US";
      // Get the Unicode flag icon for the country code
      const unicodeFlag = getUnicodeFlagIcon(code.toUpperCase());
      // Store the Unicode flag icon in the component's data
      this.unicodeFlag = unicodeFlag;
      console.log("unicode", unicodeFlag);
    },
    saveChanges() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.loading = false;
        this.editProfileFlag = false;
        // call the detail api to refresh
        this.$store.dispatch("authStore/getUserDetails");

        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "green",
        });
      };

      const failureHandler = (res) => {
        console.log(res.data);
        this.loading = false;
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "red",
        });
      };
      const params = {
        name: this.new_name,
        organization_name: this.organization_name,
        country: this.country_name,
      };
      return this.$axios("patch", this.$apiUrl.editUserProfile, {
        data: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    handleCountryEvent(data) {
      this.country_name = data.name;
    },
  },
};
</script>

<style scoped>
.card_form {
  padding: 30px 0px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(127, 132, 138, 1);
}
.v-text-field--placeholder >>> input::placeholder {
  color: rgba(127, 132, 138, 1);
}
.v-input >>> input {
  color: rgba(255, 255, 255, 0.87);
}
/* .v-icon {
  color: rgba(103, 184, 39, 1) !important;
} */
.btn_bg {
  background-color: #fff;
  height: 24px;
  width: 24px;
}
.model_btn {
  margin: 0px 26% !important;
  margin-top: 28px !important;
  width: 210px;
  background: rgba(176, 203, 66, 1);
  border: none;
  border-radius: 10px;
}
.theme--light.v-select >>> .v-select__selections {
  color: rgb(255 255 255 / 87%);
}

.dialog_header {
  display: flex;
  padding: 20px;
}
.dialog_card {
  border: 0.4px;
  height: auto;
  width: 650px;
  background: rgba(32, 32, 34, 1) !important;
  color: #fff !important;
  border-radius: 16px !important;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import authStore from "./modules/authStore";
import dialog from "./modules/dialog";
import dashboard from "./modules/dashboard";
import kompressJob from "./modules/kompressJob";
import trackStore from "./modules/trackStore";
import exportNotification from "./modules/exportNotification";
Vue.use(Vuex);
const state = {};
const mutations = {};
const actions = {};
const getters = {};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    authStore,
    dialog,
    dashboard,
    kompressJob,
    trackStore,
    exportNotification
  },
});

<template>
  <v-app>
    <v-row class="ma-0">
      <div>
        <Drawer />
      </div>
      <v-col class="main">
        <Main />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Main from "./Main.vue";
import Drawer from "./Drawer.vue";
export default {
  name: "Base",
  data() {
    return {};
  },
  components: {
    Drawer,
    Main,
  },
  methods: {},
};
</script>

<style scoped>
.main {
  margin-left: 255px !important;
  /* position: fixed; */
}

.mdi:before,
.mdi-set {
  font: normal normal normal 16px/1 "Material Design Icons";
}
</style>

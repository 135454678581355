<template>
  <v-dialog v-model="logoutUser" width="728px !important" class="ma-0">
    <v-card class="elevation-6 card_container" style="width: 728px">
      <v-card-text class="pa-0">
        <div class="d-flex justify-center align-center">
          <span class="ml-2 lineOne">Logout Account</span>
        </div>
        <div class="mt-3">
          <p class="text-center lineTwo">Are you sure want to Logout</p>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center align-center mt-8">
        <v-btn @click="closeDialog" class="close_btn">Cancel</v-btn>
        <v-btn @click="logoutAccount" class="proceed_btn">Yes, confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import authToken from "@/app-js/authToken";

export default {
  name: "confirmLogout",
  computed: {
    logoutUser: {
      get() {
        return this.$store.state.dialog.logoutUser;
      },
      set(value) {
        this.$store.commit("dialog/setLogout", value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.logoutUser = false;
    },

    logoutAccount() {
      sessionStorage.removeItem(authToken.removeAccessToken());

      this.$store.commit("dialog/update_snackbar", {
        show: true,
        message: "Logout Successful",
        type: "green",
      });
      this.$store.commit("dialog/setLogout", false);

      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.lineOne {
  font-size: 32px;
  color: white;
  font-weight: 600;
  line-height: normal;
}
.lineTwo {
  color: #a7a7a7;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
.proceed_btn {
  padding: 10px 24px !important;
  background-color: rgba(103, 184, 39, 0.64) !important;
  color: white;
  border-radius: 10px;
}
.close_btn {
  background-color: rgba(32, 32, 34, 1) !important;
  color: red;
  border-radius: 10px;
  border: 1px solid var(--m-3-sys-light-outline, #79747e);
}
</style>

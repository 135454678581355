<template>
  <v-snackbar v-model="show" :color="type" :right="true" :top="true">
    {{ message }}
    <template #action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
  }),

  computed: {
    type() {
      return this.$store.state.dialog.type;
    },
    message() {
      return this.$store.state.dialog.message;
    },
    show: {
      get() {
        return this.$store.state.dialog.show;
      },
      set(value) {
        this.$store.commit("dialog/update_snackbar", value);
      },
    },
  },
};
</script>
<style scoped></style>

export default {
    namespaced: true,
    state: {
        exportNotificationVmodel : false,
        apiData : '',
    },
    mutations: {
        setExportNotificationVmodel(state,val){
            state.exportNotificationVmodel=val
        },
        setApiData(state,val){
            state.apiData = val
        }
    },
    actions: {
     
    },
  };
  
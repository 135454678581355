import Vue from "vue";
import App from "./App.vue";
import router from "../src/router/index";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import rules from "./app-js/rules";
import messages from "./app-js/messages";
// import authApi from "../src/constants/api/authApi";
import "@/style/main.css";

import _ from "lodash";
// import authToken from "@/app-js/authToken";

import axios_req from "./app-js/new-base-axios";
import API_URL from "../src/constants/urls";
import VueApexCharts from "vue-apexcharts";
// Eventbus globally declare
export const eventBus = new Vue();

const eventBus1 = new Vue();
Vue.prototype.eventBus1 = eventBus1;
Vue.config.productionTip = false;
// Vue.prototype.$urls = urls;
Vue.prototype.$rules = rules;
Vue.prototype.$messages = messages;
Vue.prototype.$axios = axios_req;
Vue.prototype.$apiUrl = API_URL;
Vue.prototype.$_ = _;
// export const EventBus = new Vue();
// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// console.log("seee", process.env);

// Vue.mixin({
// beforeRouteUpdate: function (to, from, next) {
//   let title = to.meta.tab_title;
//   let permission_list = sessionStorage.getItem("tab_permissions") || [];

//   // let has_access = permission_list.includes(title);
//   // if (permission_list) {
//   if (to.meta.requiresAuth && permission_list.includes(title)) {
//     next();
//   } else {
//     next({ name: "login" });
//   }
//   // }
//   // else{
//   //   this.router.push({name: "login"})
//   // }
//   // if (to.name !== "Login" && !has_access) {
//   //   next({ name: "login" });
//   // } else {
//   //   next();
//   // }

//   // if (to.meta.requiresAuth == true) {
//   //   let permission_list = sessionStorage.getItem("tab_permissions") || [];
//   //   if (permission_list.includes(to.meta.title)) {
//   //     console.log(permission_list.includes(to.meta.title));
//   //     next();
//   //   } else {
//   //     console.log("denied");
//   //     alert("Access Denied");
//   //     next(from);
//   //   }
//   // }
// },

//   methods: {
//     showSnackBar(color, time, text) {
//       this.$store.dispatch("snackBar/Snackbar", {
//         show: true,
//         color: color,
//         message: text,
//         timeout: time,
//       });
//     },

//     formate_date(value) {
//       let date = value;
//       const [year, month, day] = date.split("-");
//       return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
//     },

//     /**
//      * Change LOS loan status.
//      * @param {Object} loan_status - Next loan status.
//      */
//     updateLosStep(loan_status) {
//       // console.log("loan_status",loan_status);
//       let formdata = {
//         loan_status: loan_status,
//       };
//       let look_up_key = this.$route.params.id;

//       const successHandler = () => {
//         this.$store.dispatch("losDetails/setCount");
//       };
//       const failureHandler = () => {
//         return false;
//       };

//       return this.$Axios("put", this.$apiUrl.UPDATE_LOS_LOAN_STATUS, {
//         data: formdata,
//         lookUpKey: look_up_key,
//         onSuccess: successHandler,
//         onFailure: failureHandler,
//         isTokenRequired: true,
//       });
//     },

//     hasEditPermission(value) {
//       let edit_permissions = sessionStorage.getItem("edit_permissions");

//       if (edit_permissions.includes(value)) {
//         return true;
//       } else {
//         return false;
//       }
//     },
//     hasSpecialPermission(value) {
//       let special_permissions = sessionStorage.getItem("special_permissions");

//       if (special_permissions.includes(value)) {
//         return true;
//       } else {
//         return false;
//       }
//     },
//   },
// });

new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-navigation-drawer absolute class="navigation-drawer" permanent>
    <div class="drawer">
      <!-- Logo at the Top -->
      <div class="sidelogo-con">
        <v-img
          :src="require(`@/assets/images/nyunZero.png`)"
          class="ma-3 side-logo"
          alt="Logo"
        ></v-img>
      </div>

      <!-- Menu Items -->
      <div class="pl-2">
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.url"
              link
              class="main_list"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <div style="position: absolute; bottom: 0">
            <v-list-item
              v-for="dynamicItem in dynamicItems"
              :key="dynamicItem.title"
              class="user-info"
              link
              :to="dynamicItem.url"
            >
              <v-list-item-icon>
                <v-icon>{{ dynamicItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ dynamicItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider color="color--grey"></v-divider>

            <div
              class="d-flex align-center user-info my-7 pl-3"
              @click="redirectToProfile"
            >
              <v-list-item-avatar>
                <v-icon class="user-avatar">{{ avatarText }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ userDetails.name }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ userDetails.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </div>
          </div>
        </v-list>
      </div>

      <!-- Avatar with User Name and Email at the Bottom -->
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      selectedRoute: "dashboard",
      infraSetup: "",
      userDetails: {},
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", url: "dashboard" },
        {
          title: "Infrastructure",
          icon: "mdi-server",
          url: "infrastructure",
        },
        {
          title: "Models & Datasets",
          icon: "mdi-file-document-outline",
          url: "modeldataset",
        },
        { title: "Jobs/Tasks", icon: "mdi-folder-outline", url: "jobTask" },
      ],
      dynamicItems: [
        {
          title: "Settings",
          icon: "mdi-cogs",
          url: "Settings",
        },
        {
          title: "Help Center",
          icon: "mdi-information",
          url: "helpAndSupport",
        },
      ],
    };
  },
  computed: {
    avatarText() {
      if (this.userDetails.name) {
        // Extract the first letters of the first name and last name
        const [firstName, lastName] = this.userDetails.name.split(" ");

        // Create the avatar text
        return `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ""}`;
      }
      return "";
    },
    getUserDetails() {
      return this.$store.state.authStore.userDetails;
    },
  },
  watch: {
    getUserDetails() {
      this.userDetails = this.getUserDetails;
    },
  },
  mounted() {
    this.profileDetails();
  },
  methods: {
    redirectToProfile() {
      const currentRoute = this.$route.name;

      if (currentRoute !== "userProfile") {
        this.$router.push({ name: "userProfile" });
      }
    },
    // user details for user

    async profileDetails() {
      await this.$store.dispatch("authStore/getUserDetails");
      this.userDetails = this.getUserDetails;
    },
  },
};
</script>

<style scoped>
.navigation-drawer {
  height: 100%;
  background: rgba(32, 32, 34, 1);
  max-width: 272px !important;
  position: fixed !important;
}

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgba(255, 255, 255, 1);
}

.v-icon {
  color: rgba(168, 176, 185, 1);
}

.side-logo {
  height: 45px;
  max-width: 172px;
}

.sidelogo-con {
  padding: 24px;
}

.v-list--dense .v-list-item {
  height: 48px;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px;
}

.v-list--dense .v-list-item .v-list-item__icon {
  height: 20px !important;
  margin-top: 10px;
  margin-bottom: 8px;
}

.v-list-item-group .v-list-item--active {
  color: #b9cd4a;
}

.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1rem;
}

.user-avatar {
  width: 40px;
  height: 40px;
  background-color: #61aa41;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.v-list-item__subtitle {
  font-size: 10px;
}

.v-navigation-drawer .v-divider {
  border-color: #f1f1f1;
}
.selected-item {
  color: rgba(176, 203, 66, 1);
}
.v-list-item-group .v-list-item--active[data-v-01ccd0b6] {
  color: rgba(176, 203, 66, 1);
}
.user-info:hover {
  /* background-color: rgba(176, 203, 66, 1); */
  cursor: pointer;
}
.v-list-item--active {
  background-color: rgba(176, 203, 66, 1);
  color: #fff !important;
}
.v-list {
  background: #ffffff;
  color: rgb(255 255 255 / 87%);
}
</style>

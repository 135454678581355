import API_URL from "@/constants/urls";
import axios from "@/app-js/new-base-axios";
export default {
  namespaced: true,

  state: {
    listAnalyseTask: [],
    listInputDtype: [],
    listQuantization: [],
    listCompilers: [],
    listDevices: [],
    listFramework: [],
  },
  mutations: {
    setListAnalyseTask(state, params) {
      state.listAnalyseTask = params;
    },
    setListInputDtype(state, params) {
      state.listInputDtype = params;
    },
    setListQuantization(state, params) {
      state.listQuantization = params;
    },
    setListCompilers(state, params) {
      state.listCompilers = params;
    },
    setListDevices(state, params) {
      state.listDevices = params;
    },
    setListFramework(state, params) {
      state.listFramework = params;
    },
  },
  actions: {
    // dropdowns-------
    getListAnalyseTask({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let analyseTask = res.data.result;
        commit("setListAnalyseTask", analyseTask);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.listAnalyseTask, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    getListInputDtype({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let inputDtype = res.data.result;
        commit("setListInputDtype", inputDtype);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.listInputDtype, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    getListQuantization({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let quantization = res.data.result;
        commit("setListQuantization", quantization);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.listQuantization, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    getListCompilers({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let compilers = res.data.result;
        commit("setListCompilers", compilers);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.listCompilers, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getListDevices({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let devices = res.data.result;
        commit("setListDevices", devices);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.listDevices, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getFramework({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let framework = res.data.result;
        commit("setListFramework", framework);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.listFramework, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // create analyse job --------
  },
};

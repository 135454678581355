import API_URL from "@/constants/urls";
import axios from "@/app-js/new-base-axios";
export default {
  namespaced: true,

  state: {
    listAllKompressJob: [],
    allKompressJobPage: 0,
    listQueuedKompressJob: [],
    queuedPage: 0,
    listInProgressKompressJob: [],
    progressPage: 0,
    listCompletedKompressJob: [],
    completedPage: 0,
    listFailedKompressJob: [],
    failedPage: 0,
    kompressJobHistory: [],
    // Method Filter API
    kompressMethodList: [],
    adaptMethodList: [],
    jobDetails: {},
    loadingStat: false,
    listLoading: false,
  },
  mutations: {
    setListAllKompressJob(state, params) {
      state.listAllKompressJob = params;
    },
    setAllKompressJobPage(state, params) {
      state.allKompressJobPage = params;
    },

    setListQueuedKompressJob(state, params) {
      state.listQueuedKompressJob = params;
    },
    setQueuedPage(state, params) {
      state.queuedPage = params;
    },
    setListInProgressKompressJob(state, params) {
      state.listInProgressKompressJob = params;
    },
    setInprogressPage(state, params) {
      state.progressPage = params;
    },
    setListCompletedKompressJob(state, params) {
      state.listCompletedKompressJob = params;
    },
    setCompletedPage(state, params) {
      state.completedPage = params;
    },
    setListFailedKompressJob(state, params) {
      state.listFailedKompressJob = params;
    },
    setFailedPage(state, params) {
      state.failedPage = params;
    },
    setKompressJobHistory(state, params) {
      state.kompressJobHistory = params;
    },
    setKompressMethodList(state, params) {
      state.kompressMethodList = params;
    },
    setAdaptMethodList(state, params) {
      state.adaptMethodList = params;
    },
    setJobDetails(state, params) {
      state.jobDetails = params;
    },
    toggleLoadingStat(state) {
      state.loadingStat = !state.loadingStat;
    },
    toggleListLoading(state) {
      state.listLoading = !state.listLoading;
    },
  },
  actions: {
    // all ------------------------------------------------
    getListAllKompressJob({ commit }, { page, type, jobMethod }) {
      commit("toggleListLoading");
      const successHandler = (res) => {
        commit("toggleListLoading");
        // console.log("store ", jobMethod);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setAllKompressJobPage", allPages);
        let queuedKompressList = res.data;
        commit("setListAllKompressJob", queuedKompressList);
      };

      const failureHandler = (res) => {
        commit("toggleListLoading");
        console.log(res.data);
      };

      const params = {
        page: page || 1,
        ...(type &&
          (type === "Kompress" || type === "Adapt") && { job_service: type }),
        ...(jobMethod && { method: jobMethod }),
      };
      return axios("get", API_URL.listAllKompressJob, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // list ----------------------------------------
    getListQueuedKompressJob({ commit }, { page, type, jobMethod }) {
      commit("toggleListLoading");
      const successHandler = (res) => {
        commit("toggleListLoading");
        // console.log(res.data);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setQueuedPage", allPages);
        let queuedKompressList = res.data;
        commit("setListQueuedKompressJob", queuedKompressList);
      };

      const failureHandler = (res) => {
        commit("toggleListLoading");
        console.log(res.data);
      };
      const job_status = "Job Created";
      const params = {
        page,
        job_status,
        ...(type &&
          (type === "Kompress" || type === "Adapt") && { job_service: type }),
        ...(jobMethod && { method: jobMethod }),
      };
      return axios("get", API_URL.listAllKompressJob, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // progress ---------------------------
    getListInProgressKompressJob({ commit }, { page, type, jobMethod }) {
      commit("toggleListLoading");
      const successHandler = (res) => {
        commit("toggleListLoading");
        // console.log(res.data);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setInprogressPage", allPages);
        let progressKompressList = res.data;
        commit("setListInProgressKompressJob", progressKompressList);
      };

      const failureHandler = (res) => {
        commit("toggleListLoading");
        console.log(res.data);
      };
      const job_status = "Job Started";
      const params = {
        page,
        job_status,
        ...(type &&
          (type === "Kompress" || type === "Adapt") && { job_service: type }),
        ...(jobMethod && { method: jobMethod }),
      };
      return axios("get", API_URL.listAllKompressJob, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // completed --------------------------------
    getListCompletedKompressJob({ commit }, { page, type, jobMethod }) {
      commit("toggleListLoading");
      const successHandler = (res) => {
        commit("toggleListLoading");
        // console.log(res.data);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setCompletedPage", allPages);
        let completedKompressList = res.data;
        commit("setListCompletedKompressJob", completedKompressList);
      };

      const failureHandler = (res) => {
        commit("toggleListLoading");
        console.log(res.data);
      };
      const job_status = "Job Completed";
      const params = {
        page,
        job_status,
        ...(type &&
          (type === "Kompress" || type === "Adapt") && { job_service: type }),
        ...(jobMethod && { method: jobMethod }),
      };
      return axios("get", API_URL.listAllKompressJob, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // failed -----------------------------------
    getListFailedKompressJob({ commit }, { page, type, jobMethod }) {
      commit("toggleListLoading");
      const successHandler = (res) => {
        commit("toggleListLoading");
        // console.log(res.data);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setFailedPage", allPages);
        let failedKompressList = res.data;
        commit("setListFailedKompressJob", failedKompressList);
      };

      const failureHandler = (res) => {
        commit("toggleListLoading");
        console.log(res.data);
      };
      const job_status = "Job Failed";
      const params = {
        page,
        job_status,
        ...(type &&
          (type === "Kompress" || type === "Adapt") && { job_service: type }),
        ...(jobMethod && { method: jobMethod }),
      };
      return axios("get", API_URL.listAllKompressJob, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // Kompress job History --------------------------------
    getKompressJobHistory(
      { commit },
      { id, job_service, job_status, page, router }
    ) {
      const successHandler = (res) => {
        let jobHistory = res.data;
        commit("setKompressJobHistory", jobHistory);
        localStorage.setItem("myjobId", id);
        if (job_service && job_status) {
          localStorage.setItem("myService", job_service); //only storing this val for download logs
          localStorage.setItem("myStatus", job_status); // only storing this val for showing download btn
        }

        // console.log(router);
        if (router.currentRoute.name !== "kompressHistory") {
          router.push({ name: "kompressHistory" });
        }
      };

      const failureHandler = (res) => {
        console.log(res);
      };
      const jobId = id;

      // const apiUrlWithParams = `${API_URL.kompressJobHistory}?id=${jobId}&page=${page}`;
      let apiUrlWithParams;
      if (job_service) {
        if (job_service === "Kompress") {
          apiUrlWithParams = `${API_URL.kompressJobHistory}?id=${jobId}&page=${page}`;
        } else if (job_service === "Adapt") {
          apiUrlWithParams = `${API_URL.adaptJobHistory}?id=${jobId}&page=${page}`;
        } else {
          console.error("Invalid job_service:", job_service);
          return; // or throw an error, or set a default API URL
        }
      }
      return axios("get", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    getStopjob({ dispatch, commit }, params) {
      commit("toggleLoadingStat");

      const successHandler = (res) => {
        console.log(res);
        commit("toggleLoadingStat");

        dispatch(
          "dialog/updateSnackbar",
          {
            show: true,
            message: res.data.result,
            type: "green",
          },
          { root: true }
        );
        dispatch("getListInProgressKompressJob", { root: true });
        dispatch("getListQueuedKompressJob", { root: true });
        dispatch("getListFailedKompressJob", { root: true });
        dispatch("getListCompletedKompressJob", { root: true });
      };

      const failureHandler = (res) => {
        commit("toggleLoadingStat");
        dispatch(
          "dialog/updateSnackbar",
          {
            show: true,
            message: res.data.result,
            type: "red",
          },
          { root: true }
        );
      };
      return axios("post", API_URL.stopJob, {
        data: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // job method filter API call

    getKompressMethodList({ commit }) {
      const successHandler = (res) => {
        // console.log("method", res);
        let kompressMethod = res.data.result;
        commit("setKompressMethodList", kompressMethod);
      };

      const failureHandler = (res) => {
        console.log(res);
      };
      return axios("get", API_URL.listKompressMethod, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getAdaptMethodList({ commit }, id) {
      const successHandler = (res) => {
        console.log(res);
        let adaptMethod = res.data.result;
        commit("setAdaptMethodList", adaptMethod);
      };

      const failureHandler = (res) => {
        console.log(res);
      };
      const url = `${API_URL.listAdaptMethod}?adapt_task_name=${id}`;

      return axios("get", url, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};

<template>
  <v-app>
    <v-main>
      <v-container class="d-flex justify-center align-center">
        <v-stepper v-model="e1" class="stepper">
          <v-stepper-items>
            <v-stepper-content :step="1">
              <v-card class="elevation-6 card_container" style="width: 630px !important">
                <div class="textcenter">
                  <h1 class="custom-h1">Signup Form</h1>
                  <p class="custom-p mt-2">Please fill your business details</p>
                </div>
                <v-card-text>
                  <v-form class="custom-form" @submit.prevent="submitForm" ref="signup_Form">
                    <v-label class="custom-label">Business Email*</v-label>
                    <v-text-field v-model="business_mail" placeholder="Enter Your Business Email ID" required outlined
                      style="border-radius: 8px" color="white" dense class="mt-2 text-white"
                      :rules="[this.$rules.emailValidation]"></v-text-field>
                    <v-label class="custom-label">Password*</v-label>
                    <v-text-field v-model="password" placeholder="Enter Your Password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" required
                      :rules="[this.$rules.passwordValidation]" :type="showPassword ? 'text' : 'password'" outlined
                      dense style="border-radius: 8px" class="mt-2">
                      <template v-slot:append>
                        <v-icon :color="showPassword ? 'white' : 'grey'" @click="togglePasswordVisibility">
                          {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                        </v-icon>
                      </template>
                    </v-text-field>

                    <v-label class="custom-label">Organization Name*</v-label>
                    <v-text-field v-model="org_name" placeholder="Enter Name of your organization " required
                      :rules="[this.$rules.required]" outlined color="deep-purple lighten-1" style="border-radius: 8px"
                      dense class="mt-2"></v-text-field>
                    <v-label class="custom-label">Your Name*</v-label>
                    <v-text-field v-model="user_name" placeholder="Enter Your Name" outlined
                      :rules="[this.$rules.nameValidation]" dense color="deep-purple lighten-1"
                      style="border-radius: 8px" required class="mt-2"></v-text-field>
                    <!-- menu list from component  -->
                    <menu-list @selectedCountry="handleCountryEvent"></menu-list>

                    <v-checkbox v-model="agree" color="green white" class="check" :rules="[this.$rules.checkboxRule]">
                      <template v-slot:label>
                        <span class="highlight-text ml-1">
                          I agree with the
                          <span @click="openTerms()" class="bold-txt">Terms of Service and Privacy Policy.</span>
                        </span>
                      </template>
                    </v-checkbox>
                  </v-form>
                  <v-card-actions>
                    <v-btn class="custom-btn" type="submit" color="white" outlined :loading="loading" dark dense
                      @click="submitForm" :disabled="loading">Proceed
                    </v-btn>
                  </v-card-actions>
                  <div class="login_text mt-3 ml-6 pl-8">
                    <span>Already have an account ?
                      <router-link to="/login" class="font-bold">Login</router-link>
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content :step="2">
              <v-container class="d-flex justify-center align-center">
                <signup-verify ref="childRef" />
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import axios_instance from "@/app-js/helper-axios";
import MenuList from "@/components/MenuList.vue";
import signupVerify from "./signupVerify.vue";

export default {
  name: "Signup",

  components: {
    MenuList,
    "signup-verify": signupVerify,
  },
  data() {
    return {
      users: [],
      business_mail: "",
      password: "",
      org_name: "",
      user_name: "",
      country_name: "",
      country_code: "", // iso2
      country: "",
      agree: false,
      showPassword: false,
      loading: false,

      e1: 1,
      steps: 2,
    };
  },

  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    async submitForm() {
      if (this.$refs.signup_Form.validate() && this.country_name !== "null") {
        this.loading = true;
        const successHandler = (res) => {
          this.loading = false;
          console.log(res.data);
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: res.data.detail,
            type: "green",
          });

          this.nextStep(1);
          // as the component loads startTimer function gets trigger
          this.$refs.childRef.startTimer();
        };

        const failureHandler = (res) => {
          this.loading = false;
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: res.data.detail,
            type: "red",
          });
        };
        const params = {
          email: this.business_mail,
          password: this.password,
          name: this.user_name,
          organization_name: this.org_name,
          country: this.country_name,
          country_code: this.country_code,
        };
        this.$store.commit("authStore/setSignupDetails", params);
        return this.$axios("post", this.$apiUrl.signUpApi, {
          data: params,
          onSuccess: successHandler,
          onFailure: failureHandler,
          isTokenRequired: false,
        });
      } else {
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: "Please Check Your Provided Details",
          type: "red",
        });
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    handleCountryEvent(data) {
      this.country_name = data.name;
      this.country_code = data.iso2;
      console.log("gettingData", this.country_code);
    },
    openTerms() {
      window.open('https://nyun-server-bucket.s3.ap-south-1.amazonaws.com/static/terms_policy/terms.pdf');
    }
    // handleDialCodeEvent(data) {
    //   this.dialCode = data.dialCode;
    // },
    // handlePhoneEvent(data) {
    //   // console.log("phoneemit", data);
    //   this.ph_num = data;
    // },
  },
};
</script>

<style scoped>
.highlight-text {
  font-size: 15px;
  width: 400px;
  font-weight: 400;
  font-family: poppins;
  color: rgba(110, 123, 137, 1);
}

.login_text {
  font-size: 15px;
  width: 400px;
  font-weight: 400;
  font-family: poppins;
  /* color: rgba(110, 123, 137, 1); */
  color: white;
}

.card_container {
  padding: 30px 32px;
}

.bold-txt {
  color: white;
  text-decoration: underline;
}

.v-text-field--outlined>>>fieldset {
  border-color: rgba(127, 132, 138, 1);
}

.v-text-field--placeholder>>>input::placeholder {
  color: rgba(127, 132, 138, 1);
}

.v-input>>>input {
  color: rgba(255, 255, 255, 0.87);
}

/* .check >>> .theme--light.v-icon {
  color: rgb(255 255 255 / 54%) !important;
} */
::v-deep .v-messages__message {
  color: rgb(255, 0, 0);
}

.v-stepper {
  background: #000;
}

/* checkbox */
.theme--light>>>.v-icon {
  color: #49454f;
}

.v-application a {
  color: #b0cb42;
}
</style>

import API_URL from "@/constants/urls";
import axios from "@/app-js/new-base-axios";
export default {
  namespaced: true,

  state: {
    signupDetails: {},
    changePassword: {},
    userDetails: {},
  },
  mutations: {
    setSignupDetails(state, params) {
      state.signupDetails = params;
    },
    setChangePassword(state, params) {
      state.changePassword = params;
    },
    setUserDetails(state, params) {
      state.userDetails = params;
    },
  },
  actions: {
    getUserDetails({ commit }) {
      const successHandler = (res) => {
        let userDetails = res.data.result;
        commit("setUserDetails", userDetails);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.userProfileDetails, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};

export default {
  required: (value) => !!value || "This is Required.",
  gst_required: (value) => value != null || "This is Required.",
  required_array: (value) => !!value[0] || "This is Required.",

  phone: (v) => {
    const re = /^(\+91)?[0]?(91)?[6789]\d{9}$/;
    if (re.test(v)) {
      return true;
    }
    return "Invalid Phone Number";
  },

  passwordValidation: (password) => {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (
      password.length >= minLength &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password)
    ) {
      return true;
    }

    return "Password must have at least 8 characters, including one uppercase letter and one special character.";
  },
  emailValidation: (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      return true;
    }

    return "Please enter a valid email address.";
  },
  phone_optional: (v) => {
    const re = /^(\+91)?[0]?(91)?[6789]\d{9}$/;
    if (v === null || v === "") {
      return true;
    } else if (re.test(v)) {
      return true;
    }
    return "Invalid Phone Number";
  },

  text: (v) => {
    const re = /^[A-Za-z\s]+$/;
    if (re.test(v)) {
      return true;
    }
    return "Only Character";
  },
  number: (v) => {
    const re = /^[0-9]+$/;
    if (re.test(v)) {
      return true;
    }
    return "Only Numbers";
  },
  url: (v) => {
    const re = /^(http|https):\/\/+[\www\d]+\.[\w]+(\/[\w\d]+)?/;
    if (re.test(v) || !v) {
      return true;
    }
    return "Invalid Url";
  },

  ifsc_code: (v) => {
    const re = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (re.test(v)) {
      return true;
    }
    return "IFSC code must be Alpha Numeric and all the letter should be Capital";
  },
  checkboxRule: (v) => {
    if (v) {
      return true;
    }
    return "You must agree to the terms and conditions.";
  },

  // model upload

  letterCharValid: (input) => {
    const regex = /^[a-zA-Z ]+$/;

    if (regex.test(input)) {
      return true;
    }

    return "Please enter only letters and characters.";
  },
  nameValidation(input) {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow only letters and spaces

    if (regex.test(input)) {
      return true;
    }

    return "Please enter only letters in the name field.";
  },
};

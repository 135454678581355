<template>
  <v-dialog v-model="comingSoonFlag" width="728px !important" class="ma-0">
    <v-card class="elevation-6 card_container" style="width: 728px">
      <v-card-text class="pa-0">
        <div class="d-flex justify-center align-center">
          <span class="ml-2 lineOne">Coming Soon</span>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center align-center mt-8">
        <v-btn @click="closeDialog" class="close_btn">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "comingSoon",
  computed: {
    comingSoonFlag: {
      get() {
        return this.$store.state.dialog.comingSoonFlag;
      },
      set(value) {
        this.$store.commit("dialog/setComingSoonFlag", value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.comingSoonFlag = false;
    },
  },
};
</script>

<style scoped>
.lineOne {
  font-size: 32px;
  color: white;
  font-weight: 600;
  line-height: normal;
}
.lineTwo {
  color: #a7a7a7;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
.proceed_btn {
  padding: 10px 24px !important;
  background-color: rgba(103, 184, 39, 0.64) !important;
  color: white;
  border-radius: 10px;
}
.close_btn {
  background-color: rgba(32, 32, 34, 1) !important;
  color: red;
  border-radius: 10px;
  border: 1px solid var(--m-3-sys-light-outline, #79747e);
}
</style>

import API_URL from "@/constants/urls";
import axios from "@/app-js/new-base-axios";
export default {
  namespaced: true,

  state: {
    infraConnectedStatus: {},
    infraSetup: "",
    modelSetupStatus: "",
    pendingInfraStatus: null,
    modelList: [],
    allModelPage: 0,
    datasetList: [],
    datasetPage: 0,
    baselineModel: [],
    baselinePage: 0,
    optimizedModel: [],
    optimizedPage: 0,
    inProgressModel: [],
    currantPage: 1,
    kompressFormModelList: [],
    kompressFormDatasetList: [],
    infraList: {
      is_connected: false,
      is_deployed: false,
    },
  },
  mutations: {
    setInfraConnectedStatus(state, params) {
      state.infraConnectedStatus = params;
    },
    setInfraSetup(state, params) {
      state.infraSetup = params;
    },
    setPendingInfraStatus(state, params) {
      state.pendingInfraStatus = params;
    },
    setModelSetupStatus(state, params) {
      state.modelSetupStatus = params;
    },
    setCurrantPage(state, params) {
      state.currantPage = params;
    },
    setModelList(state, params) {
      state.modelList = params;
    },
    setAllModelPage(state, params) {
      state.allModelPage = params;
    },
    setDatasetList(state, params) {
      state.datasetList = params;
    },
    setDatasetPage(state, params) {
      state.datasetPage = params;
    },
    setBaselineModel(state, params) {
      state.baselineModel = params;
    },
    setBaselinePage(state, params) {
      state.baselinePage = params;
    },

    setOptimizedModel(state, params) {
      state.optimizedModel = params;
    },
    setOptimizedPage(state, params) {
      state.optimizedPage = params;
    },

    setKompressFormModelList(state, params) {
      state.kompressFormModelList = params;
    },
    setKompressFormDatasetList(state, params) {
      state.kompressFormDatasetList = params;
    },

    setInfraList(state, params) {
      state.infraList = params;
    },
  },
  actions: {
    //  infra is connected or not

    getInfraConnected({ commit }) {
      const successHandler = (res) => {
        // console.log(res);

        let status = res.data.result;
        commit("setInfraConnectedStatus", status);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.checkIsInfraConnected, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    getInfraSetupStatus({ commit }) {
      const successHandler = (res) => {
        // console.log(res.data);

        let infraSetup = res.data.result.is_infra_detail;
        commit("setInfraSetup", infraSetup);
        commit("setPendingInfraStatus", true);
        try {
          commit("setPendingInfraStatus", false);
        } catch (error) {
          commit("setPendingInfraStatus", false);
        }
      };

      const failureHandler = (res) => {
        console.log(res.data);
        commit("setPendingInfraStatus", false);
      };

      return axios("get", API_URL.checkIsInfraSetup, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // model setup or not
    getModelSetupStatus({ commit }) {
      const successHandler = (res) => {
        // console.log(res.data);
        let infraSetup = res.data.result.is_model_datset_uploaded;
        commit("setModelSetupStatus", infraSetup);
      };

      const failureHandler = (res) => {
        console.log(res.data);
        commit("setPendingInfraStatus", false);
      };

      return axios("get", API_URL.checkModelDatasetUploaded, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // get ALL user dataset list  (Models and Dataset)-----------------------------------

    // get ALL user model list
    getUserModelList({ commit }, { page }) {
      const successHandler = (res) => {
        let allPages = Math.ceil(res.data.count / 5);
        commit("setAllModelPage", allPages);
        let currentAllModels = res.data;
        commit("setModelList", currentAllModels);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };
      const apiUrlWithParams = `${API_URL.listCountAiModel}?page=${page}`;

      return axios("get", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // get ALL user dataset list

    getUserDatasetList({ commit }, { page }) {
      const successHandler = (res) => {
        // console.log("datasets", res.data);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setDatasetPage", allPages);
        let currentDatasets = res.data;
        commit("setDatasetList", currentDatasets);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };
      const apiUrlWithParams = `${API_URL.listUserDataset}?page=${page}`;

      return axios("get", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // get only BaseLine model list

    getBaselineModelList({ commit }, { page }) {
      const successHandler = (res) => {
        let allPages = Math.ceil(res.data.count / 5);
        commit("setBaselinePage", allPages);
        let baselineModel = res.data;
        // console.log(res);

        commit("setBaselineModel", baselineModel);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };
      const apiUrlWithParams = `${API_URL.listCountBaseModel}?page=${page}`;

      return axios("get", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // get only optimized model list

    getOptimizedModelList({ commit }, { page }) {
      const successHandler = (res) => {
        // console.log(res.data);
        let allPages = Math.ceil(res.data.count / 5);
        commit("setOptimizedPage", allPages);
        let optimizedModel = res.data;
        commit("setOptimizedModel", optimizedModel);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };
      const apiUrlWithParams = `${API_URL.listCountOptimizedModel}?page=${page}`;

      return axios("get", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    //  delete models api

    getDeleteModels({ dispatch, state }, id) {
      const successHandler = (res) => {
        // console.log(res.data);

        dispatch(
          "dialog/updateSnackbar",
          {
            show: true,
            message: res.data.detail,
            type: "green",
          },
          { root: true }
        );
        dispatch("getUserModelList", {
          page: state.currantPage,
        });
        dispatch("getBaselineModelList", {
          page: 1,
        });
        dispatch("getOptimizedModelList", {
          page: 1,
        });
      };

      const failureHandler = (res) => {
        console.log(res.data);
        dispatch(
          "dialog/updateSnackbar",
          {
            show: true,
            message: res.data.detail,
            type: "red",
          },
          { root: true }
        );
      };

      const modelId = id;
      const apiUrlWithParams = `${API_URL.deleteUserModel}?id=${modelId}`;

      return axios("delete", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    //  delete datsets api
    getDeleteDatasets({ commit, dispatch, state }, id) {
      const successHandler = (res) => {
        // console.log(res.data);
        commit(
          "dialog/update_snackbar",
          {
            show: true,
            message: res.data.detail,
            type: "green",
          },
          { root: true }
        );
        // calling list api
        dispatch("getUserDatasetList", {
          page: state.currantPage,
        });
      };

      const failureHandler = (res) => {
        console.log(res.data);
        commit(
          "dialog/update_snackbar",
          {
            show: true,
            message: res.data.detail,
            type: "red",
          },
          { root: true }
        );
      };
      const DatasetId = id;
      const apiUrlWithParams = `${API_URL.deleteUserDataset}?id=${DatasetId}`;
      return axios("delete", apiUrlWithParams, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    //  Model list for kompress Form menu

    getKompressFormModelList({ commit }) {
      const successHandler = (res) => {
        let kompressModelList = res.data.result;
        // console.log(res.data);

        commit("setKompressFormModelList", kompressModelList);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.kompressFormModelList, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    //  Dataset list for kompress Form menu

    getKompressFormDatasetList({ commit }) {
      const successHandler = (res) => {
        let kompressDatasetList = res.data.result;
        console.log(res.data);

        commit("setKompressFormDatasetList", kompressDatasetList);
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };

      return axios("get", API_URL.kompressFormDatasetList, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};

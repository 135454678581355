<template>
  <v-card-text class="card_form pa-0">
    <div class="d-flex justify-center align-center">
      <v-radio-group
        v-model="dataUploadRadio"
        row
        color="#b0cb42"
        class="d-flex justify-center align-center"
      >
        <v-radio
          v-if="!generalDetailsChicked"
          label="General Details"
          value="general_details"
          class="mr-2"
        ></v-radio>
        <span v-else class="checked-span">
          <v-icon> mdi-check </v-icon> General Details
        </span>
        <v-radio
          label="Attached Files"
          value="Attached_files"
          :disabled="isAttachedFilesDisabled"
          class="ml-2"
        ></v-radio>
      </v-radio-group>
    </div>
    <v-container v-if="dataUploadRadio === 'general_details'">
      <v-form class="custom-form" @submit.prevent="submitForm" ref="form_reset">
        <v-row dense>
          <v-col cols="12">
            <v-label class="custom-label">Dataset Name*</v-label>
            <v-text-field
              v-model="dataset_name"
              :rules="[this.$rules.required]"
              placeholder="Enter your dataset name"
              outlined
              dense
              class="mt-2"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-label class="custom-label">Dataset Type*</v-label>
            <v-select
              v-model="dataset_type"
              :items="datasetType"
              :rules="[this.$rules.required]"
              item-text="dataset_type"
              placeholder="Select dataset type "
              outlined
              dense
              class="mt-2 select"
            ></v-select>
          </v-col>
        </v-row>
        <v-btn
          class="model_btn"
          color="white"
          :loading="loading"
          outlined
          dark
          dense
          @click="importDataSetGeneralDetails"
          >Import Dataset</v-btn
        >
      </v-form>
    </v-container>
    <v-container v-else>
      <v-form class="px-13">
        <v-label class="attach-file">Attach File</v-label>

        <v-container>
          <v-radio-group v-model="attachedFileDataRadio" color="#b0cb42">
            <v-radio
              label="Transfer via SCP command"
              value="scp_command"
              class="radio-btn-label"
            >
            </v-radio>
            <div v-if="attachedFileDataRadio === 'scp_command'" class="my-4">
              <div class="d-flex align-center justify-space-between">
                <v-label class="custom-label">Bash Command</v-label>
                <v-label
                  class="custom-label mr-4 cursor-pointer"
                  @click="copyPath"
                  >Copy Path</v-label
                >
              </div>
              <v-container
                style="background-color: #2b2b2b; min-height: 60px"
                rounded
                class="my-3 py-3"
              >
                <span style="color: #a19c9c">{{ scp_command }}</span>
              </v-container>
              <v-label class="custom-label" style="color: #a19c9c !important"
                >NOTE: Please replace the path with the actual file
                path</v-label
              >
            </div>
            <v-divider class="divider"></v-divider>

            <v-radio
            v-if="infraList[0]?.user_data_folder !== null"
              label="I have relative file path"
              value="relative_path"
              class="radio-btn-label"
            ></v-radio>
            <div v-if="attachedFileDataRadio === 'relative_path'">
              <v-row class="my-4">
                <v-col cols="10">
                  <v-label class="custom-label"
                    >Dataset Relative File path</v-label
                  >
                  <v-text-field
                    v-model="dataset_relative_folder_path"
                    placeholder="Enter Your Dataset Relative Path"
                    outlined
                    dense
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-divider  v-if="infraList[0]?.user_data_folder !== null" class="divider"></v-divider>
            <v-radio label="Skip" value="skip" class="radio-btn-label">
            </v-radio>
            <div v-if="attachedFileDataRadio === 'skip'"></div>
          </v-radio-group>
        </v-container>
      </v-form>
      <v-btn
        class="model_btn"
        color="white"
        :loading="loading"
        outlined
        dark
        dense
        @click="importDataSetAttachedFiles"
        >Import Dataset</v-btn
      >
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  name: "dataUpload",
  data() {
    return {
      infraList: [],
      loading: false,
      dataset_name: "",
      dataset_type: "",
      dataset_folder: "",
      datasetType: [],
      generalDetailsChicked: false,
      isAttachedFilesDisabled: true,
      scp_command: "",
      dataset_relative_folder_path: "",
      attachFileLoading: false,
    };
  },
  computed: {
    dataUploadRadio: {
      get() {
        return this.$store.state.dialog.dataUploadRadio;
      },
      set(value) {
        this.$store.commit("dialog/setDataUploadRadio", value);
      },
    },
    attachedFileDataRadio: {
      get() {
        return this.$store.state.dialog.attachedFileDataRadio;
      },
      set(value) {
        this.$store.commit("dialog/setAttachedFileDataRadio", value);
      },
    },
  },
  mounted() {
    this.datasetTypeList();
    this.listInfraDetails();
  },
  watch: {
    generalDetailsChicked(value) {
      if (value) {
        this.attachedFileDataRadio = "Attached_files";
        this.isAttachedFilesDisabled = false;
      } else {
        this.isAttachedFilesDisabled = true;
      }
    },
  },
  methods: {
    listInfraDetails() {
      const successHandler = (res) => {
        // console.log(res.data.result.length);
        this.$store.commit("dashboard/setInfraList", res.data.result);
        this.infraList = res.data.result;
        
        this.filledStorage = this.infraList[0].used_percentage.replace("%", "");

        this.isConnected();
      };

      const failureHandler = (res) => {
        console.log(res);
      };
      return this.$axios("get", this.$apiUrl.listInfraDetails, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    copyPath() {
      const path = this.scp_command;
      navigator.clipboard
        .writeText(path)
        .then(() => {
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: "Path copied to clipboard",
            type: "green",
          });
        })
        .catch((err) => {
          console.error("Failed to copy path: ", err);
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: "Failed to copy path",
            type: "red",
          });
        });
    },
    datasetTypeList() {
      const successHandler = (res) => {
        this.datasetType = res.data.result;
      };

      const failureHandler = (res) => {
        console.log(res.data);
      };
      return this.$axios("get", this.$apiUrl.listDatasetType, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },
    importDataSetAttachedFiles() {
      this.loading = true;
      const successHandler = () => {
        this.loading = false;
        let value = "general_details";
        this.$store.commit("dialog/setDataUploadRadio", value);
        this.generalDetailsChicked = false;
        this.$store.commit("dialog/setImportModelFlag", false);
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: "Operation Successful",
          type: "green",
        });
        this.$store.dispatch("dashboard/getUserDatasetList", {
          page: 1,
        });
      };
      const failureHandler = (res) => {
        this.loading = false;
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "red",
        });
      };
      const body = new FormData();
      if (
        this.attachedFileDataRadio === "scp_command" ||
        this.attachedFileDataRadio === "relative_path"
      )
        body.append("stored_status", "User Infra");
      if (this.dataset_relative_folder_path) {
        body.append(
          "dataset_relative_folder_path",
          this.dataset_relative_folder_path
        );
      }
      const params = {};
      params.id = this.formId;
      return this.$axios("patch", this.$apiUrl.updateDataSetFiles, {
        data: body,
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    importDataSetGeneralDetails() {
      if (this.$refs.form_reset.validate()) {
        this.loading = true;

        const successHandler = (res) => {
          this.loading = false;
          // console.log(res.data);
          this.formId = res.data.result.dataset_id;
          this.scp_command = res.data.result.scp_command[0];

          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: "General details sent sucessfully",
            type: "green",
          });
          this.generalDetailsChicked = true;

          this.$store.dispatch("dashboard/getUserDatasetList", {
            page: 1,
          });

          // reset form
          this.reset();
        };

        const failureHandler = (res) => {
          this.loading = false;
          console.log(res);
          this.$store.commit("dialog/update_snackbar", {
            show: true,
            message: res.data.detail,
            type: "red",
          });
        };
        const newData = new FormData();

        newData.append("dataset_type", this.dataset_type);
        newData.append("dataset_name", this.dataset_name);
        // if (this.dataset_folder) {
        //   const cleanedRelativeFolder = this.dataset_folder.startsWith("/")
        //     ? this.dataset_folder.slice(1)
        //     : this.dataset_folder;
        //   newData.append("dataset_relative_folder_path", cleanedRelativeFolder);
        //   newData.append("stored_status", "User Infra");
        // } else {
        newData.append("stored_status", "Nyun");
        // }

        const params = newData;
        setTimeout(() => {
          return this.$axios("post", this.$apiUrl.importDataset, {
            data: params,
            onSuccess: successHandler,
            onFailure: failureHandler,
            isTokenRequired: true,
          });
        }, 2000);
        // else condition -----
      } else {
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: "Fill in all required fields",
          type: "red",
        });
      }
    },

    reset() {
      this.$refs.form_reset.reset();
    },
  },
};
</script>

<style scoped>
.card_form {
  padding: 30px 0px !important;
}
.file_field {
  padding: 12px 16px;
  border: 2px solid rgba(127, 132, 138, 1);
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(127, 132, 138, 1);
}
.v-text-field--placeholder >>> input::placeholder {
  color: rgba(127, 132, 138, 1);
}
.v-input >>> input {
  color: rgba(255, 255, 255, 0.87);
}
.v-icon {
  color: rgba(176, 203, 66, 1) !important;
}
/* for default deopdown icon v-select */
.select >>> .mdi-menu-down::before {
  color: antiquewhite !important;
}
.upload-file {
  border-color: rgba(176, 203, 66, 1);
  color: rgba(176, 203, 66, 1);
  /* margin: 0px 29% !important; */
  border-radius: 20px;
}

.model_btn {
  margin: 0px 26% !important;
  margin-top: 28px !important;
  width: 210px;
  background: rgba(176, 203, 66, 1);
  border: none;
  border-radius: 10px;
}
.theme--light.v-select >>> .v-select__selections {
  color: rgb(255 255 255 / 87%);
}
.upload_btn {
  display: flex;
  justify-content: space-evenly;
}

.v-input--selection-controls .v-radio >>> .v-label {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: white;
}
.radio >>> .v-input--selection-controls__ripple {
  color: #b0cb42 !important;
}
.v-application >>> .primary--text {
  color: #b0cb42 !important;
}
.radio >>> .v-input--selection-controls__ripple {
  color: #b0cb42 !important;
}
.v-radio >>> .v-icon {
  color: #b0cb42 !important;
}

.attach-file {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
}

.radio-btn-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: white;
}
.v-input--selection-controls .v-radio >>> .v-label {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: white;
}

.divider {
  border: 1px solid #494949;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checked-span {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: white;
}
</style>

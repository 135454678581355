<template>
  <v-dialog
    v-model="importModelFlag"
    style="overflow-y: hidden !important"
    width="580px !important"
    class="dialog"
    scrollable
  >
    <v-card class="elevation-6 wizard_card">
      <v-card-title class="wizard_header">
        <h3 class="custom-p">Import Wizard</h3>
        <v-spacer></v-spacer>
        <v-btn icon class="color--white" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider color="white" style="width: 100%"></v-divider>

      <v-radio-group v-model="selectedRadio" row>
        <div class="radio_box">
          <v-radio
            v-for="(item, i) in radioBtn"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></v-radio>
        </div>
      </v-radio-group>

      <!-- Conditionally render components based on selectedTab -->
      <template v-if="selectedTab === 'models'">
        <modelUpload ref="form_reset" />
      </template>
      <template v-else-if="selectedTab === 'datasets'">
        <dataUpload ref="form_reset" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import dataUpload from "./dataUpload.vue";
import modelUpload from "./modelUpload.vue";

export default {
  name: "importModel",
  components: {
    dataUpload,
    modelUpload,
  },
  data() {
    return {
      selectedRadio: "models",
      radioBtn: [
        {
          label: "Model Upload",
          value: "models",
        },
        {
          label: "Data Upload",
          value: "datasets",
        },
      ],
    };
  },
  computed: {
    importModelFlag: {
      get() {
        return this.$store.state.dialog.importModelFlag;
      },
      set(value) {
        this.$store.commit("dialog/setImportModelFlag", value);
      },
    },
    selectedTab() {
      return this.$store.state.dialog.selectedTab;
    },
  },
  watch: {
    selectedRadio(newVal) {
      // Update selectedTab based on the selected radio button
      this.$store.commit("dialog/setSelectedTab", newVal);
    },
    selectedTab(newVal) {
      // Update selectedRadio based on the selected tab
      if (newVal === "datasets") {
        this.selectedRadio = "datasets";
      } else {
        this.selectedRadio = "models";
      }
    },
  },

  methods: {
    closeDialog() {
      this.$store.commit("dialog/setModelUploadRadio", "general_details");
      this.$store.commit("dialog/setDataUploadRadio", "general_details");
      this.importModelFlag = false;
      this.$refs.form_reset.reset();
    },
  },
};
</script>

<style scoped>
.v-icon {
  /* color: white !important; */
  background: #c9cbcd;
  padding: 6px;
  border-radius: 50%;
}

.wizard_card {
  border: 0.4px;
  height: auto;
  width: 650px;
  background: rgba(32, 32, 34, 1) !important;
  color: #fff !important;
  border-radius: 16px !important;
  /* padding: 30px; */
}
.wizard_header {
  display: flex;
  padding: 20px;
}
.radio_box {
  width: 500px !important;
  padding: 15px 20px;
  background: #2e2e2e;
  display: flex;
  border-radius: 10px;
  justify-content: space-around;
  margin: 0 auto;
}
.v-btn:not(.v-btn--round).v-size--default {
  background: #b0cb42;
  color: white;
  margin-top: 30px;
}
.v-application >>> .primary--text {
  color: #b0cb42 !important;
}
.radio >>> .v-input--selection-controls__ripple {
  color: #b0cb42 !important;
}
.v-radio >>> .v-icon {
  color: #b0cb42 !important;
}
.v-radio {
  color: #b0cb42 !important;
}
.v-input--selection-controls .v-radio >>> .v-label {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #b0cb42;
}
</style>
<!-- color: rgba(103, 184, 39, 1); -->

<template>
  <v-dialog v-model="exportModelFlag" width="644px !important" class="ma-0">
    <v-card class="elevation-6 dialog_card">
      <div class="dialog_header">
        <h3 class="custom-p">Export Model</h3>
        <v-spacer></v-spacer>
        <v-btn icon class="color--white btn_bg" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider color="white" style="width: 100%"></v-divider>
      <v-card-text class="card_form pa-0">
        <v-form class="custom-form" @submit.prevent="submitForm">
          <v-row dense>
            <!-- Dropdown Field -->
            <v-col cols="12">
              <v-label class="custom-label">Model</v-label>
              <v-select
                v-model="model"
                placeholder="Select a model"
                item-text="gpu_number"
                required
                outlined
                height="48px"
                style="border-radius: 8px"
                color="green lighten-1"
                dense
                class="mt-2 select"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-label class="custom-label">Device</v-label>
              <v-select
                v-model="device"
                placeholder="Select a model"
                item-text="gpu_number"
                required
                outlined
                height="48px"
                style="border-radius: 8px"
                color="green lighten-1"
                dense
                class="mt-2 select"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-label class="custom-label">Format</v-label>
              <v-select
                v-model="format"
                placeholder="Select a Format"
                item-text="gpu_number"
                required
                outlined
                height="48px"
                style="border-radius: 8px"
                color="green lighten-1"
                dense
                class="mt-2 select"
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-divider color="white" style="width: 100%"></v-divider> -->

          <v-btn
            class="model_btn"
            color="white"
            :loading="loading"
            outlined
            dark
            dense
            @click="exportModel"
            >Export Model</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "exportModel",

  data() {
    return {
      loading: false,
      model: "",
      device: "",
      format: "",
    };
  },
  computed: {
    exportModelFlag: {
      get() {
        return this.$store.state.dialog.exportModelFlag;
      },
      set(value) {
        this.$store.commit("dialog/setExportModelFlag", value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.exportModelFlag = false;
    },
    exportModel() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.loading = false;
        this.exportModelFlag = false;

        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "green",
        });
      };

      const failureHandler = (res) => {
        console.log(res.data);
        this.loading = false;
        this.$store.commit("dialog/update_snackbar", {
          show: true,
          message: res.data.detail,
          type: "red",
        });
      };
      const params = {
        mobile: this.ph_num,
        organization_name: this.organization_name,
        country: this.country_name,
      };
      return this.$axios("patch", this.$apiUrl.editUserProfile, {
        data: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.card_form {
  padding: 30px 0px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(127, 132, 138, 1);
}
.v-text-field--placeholder >>> input::placeholder {
  color: rgba(127, 132, 138, 1);
}
.v-input >>> input {
  color: rgba(255, 255, 255, 0.87);
}
/* .v-icon {
    color: rgba(103, 184, 39, 1) !important;
  } */
.btn_bg {
  background-color: #fff;
  height: 24px;
  width: 24px;
}
.model_btn {
  margin: 0px 26% !important;
  margin-top: 28px !important;
  width: 210px;
  background: rgba(176, 203, 66, 1);
  border: none;
  border-radius: 10px;
}
.theme--light.v-select >>> .v-select__selections {
  color: rgb(255 255 255 / 87%);
}

.dialog_header {
  display: flex;
  padding: 20px;
}
.dialog_card {
  border: 0.4px;
  height: auto;
  width: 650px;
  background: rgba(32, 32, 34, 1) !important;
  color: #fff !important;
  border-radius: 16px !important;
}
.select >>> .mdi-menu-down::before {
  margin-top: 5px;
  color: antiquewhite !important;
}
</style>

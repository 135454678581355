<template>
  <v-app id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
    </nav> -->
    <router-view />
    <snackbar />
    <importWizard />
    <infraSetupDialog />
    <infraConnectedDialog />
    <editProfile />
    <exportModel />
    <optimizeModel />
    <comingSoon />
    <confirmLogout />
    <ticketDialog />
  </v-app>
</template>
<script>
import snackbar from "@/components/dialogs/snackbox.vue";
import importWizard from "./components/dialogs/importWizard.vue";
import infraSetupDialog from "./components/dialogs/infraSetupDialog.vue";
import editProfile from "./components/dialogs/editProfile.vue";
import exportModel from "./components/dialogs/exportModel.vue";
import optimizeModel from "./components/dialogs/optimizeModel.vue";
import infraConnectedDialog from "./components/dialogs/infraConnectedDialog.vue";
import comingSoon from "./components/dialogs/comingSoon.vue";
import confirmLogout from "./components/dialogs/confirmLogout.vue";
import ticketDialog from "./components/dialogs/ticketDialog.vue";
export default {
  name: "App",
  components: {
    snackbar,
    importWizard,
    infraSetupDialog,
    infraConnectedDialog,
    editProfile,
    exportModel,
    optimizeModel,
    comingSoon,
    confirmLogout,
    ticketDialog,
  },
};
</script>

<style lang="scss">
body {
  background: #000;
}
#app {
  height: 100%;
  font-family: "poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #ffffff;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

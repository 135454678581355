<template>
  <v-dialog
    v-model="optimizeDialogFlag"
    width="744px !important"
    class="ma-0 scroll"
  >
    <v-card class="elevation-6 dialog_card">
      <div class="dialog_header">
        <h3 class="custom-p">Optimise Model</h3>
        <v-spacer></v-spacer>
        <v-btn icon class="color--white btn_bg" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-divider color="white" style="width: 100%"></v-divider>

      <div class="mt-1">
        <h1 class="mt-3 infra-title">Choose a service</h1>
      </div>

      <v-radio-group v-model="selectedRadio" class="card_radio">
        <div
          v-for="option in radioOptions"
          :key="option.value"
          :class="{ 'radio-selected': selectedRadio === option.value }"
          class="radio-box mt-6"
        >
          <v-radio :value="option.value" class="radio">
            <template v-slot:label>
              <div class="ml-3">
                <img
                  :src="option.imageSrc"
                  alt="Radio Option Image"
                  class="radio-image"
                />
                <p class="radio-para">{{ option.description }}</p>
              </div>
            </template>
          </v-radio>
        </div>
      </v-radio-group>

      <div justify="center" align="center">
        <v-btn @click="continueClicked" class="px-5 mb-8">Continue</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "optmizeModel",

  data() {
    return {
      loading: false,
      selectedRadio: "nyunCloud",
      radioOptions: [
        {
          value: "nyunTrack",
          imageSrc: require("@/assets/images/nyunTrack.png"),
          description:
            "Monitor and compare key metrics for your deep learning models.",
        },
        {
          value: "kompress",
          imageSrc: require("@/assets/images/kompress.png"),
          description: " Optimize deep learning models using SOTA techniques.",
        },
        {
          value: "adapt",
          imageSrc: require("@/assets/images/adapt.png"),
          description:
            "Efficiently Finetune Generative AI models on your own Data.",
        },
      ],
    };
  },
  computed: {
    optimizeDialogFlag: {
      get() {
        return this.$store.state.dialog.optimizeDialogFlag;
      },
      set(value) {
        this.$store.commit("dialog/setOptimizeDialogFlag", value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.optimizeDialogFlag = false;
    },
    continueClicked() {
      if (this.selectedRadio === "nyunTrack") {
        this.$router.push({ name: "tracker" });
        // this.$store.commit("dialog/setComingSoonFlag", true);
        this.$store.commit("dialog/setOptimizeDialogFlag", false);
      } else if (this.selectedRadio === "kompress") {
        this.$router.push({ name: "kompress" });
        this.$store.commit("dialog/setOptimizeDialogFlag", false);
      } else if (this.selectedRadio === "adapt") {
        this.$router.push({ name: "adapt" });
        this.$store.commit("dialog/setOptimizeDialogFlag", false);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(127, 132, 138, 1);
}
.v-text-field--placeholder >>> input::placeholder {
  color: rgba(127, 132, 138, 1);
}
.v-input >>> input {
  color: rgba(255, 255, 255, 0.87);
}

.theme--light.v-select >>> .v-select__selections {
  color: rgb(255 255 255 / 87%);
}

.infra-title {
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.dialog_card {
  border: 0.4px;
  height: auto;
  width: 744px;
  background: rgba(32, 32, 34, 1) !important;
  color: #fff !important;
  border-radius: 16px !important;
}
.dialog_header {
  display: flex;
  padding: 20px;
}
.btn_bg {
  background-color: #fff;
  height: 24px;
  width: 24px;
}
.card_radio {
  padding: 10px;
  margin-left: 35px !important;
}
.radio-para {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #828282;
}
.radio-image {
  width: 184px;
  height: 50px;
}
.radio-box {
  max-width: 650px;
  background: #2e2e2e;
  padding: 30px;
  border-radius: 28px;
}

.v-btn:not(.v-btn--round).v-size--default {
  max-width: 125px;
  padding: 16px 20px;
  background: #b0cb42;
  color: white;
  margin-top: 30px;
  border-radius: 10px;
}
.radio >>> .v-input--selection-controls__ripple {
  color: #b0cb42 !important;
}
.v-radio >>> .v-icon {
  color: #b0cb42 !important;
}
.v-radio {
  color: #b0cb42 !important;
}
.radio-selected {
  background: #262c22;
}
</style>
